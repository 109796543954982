<template>
  <div class="pb-24">


    <TheHero />
    
    <section class="mx-3 lg:mx-14 mb-14" id="about-me">

      <div class="text-firstColor  text-2xl mb-6 lg:text-6xl">About</div>
      <div class="flex flex-col items-center lg:flex-row-reverse  ">

        <img class=" w-32 h-32 mb-6 mx-auto rounded-full lg:w-64 lg:h-64 lg:mb-0" src="~@/assets/compressedProfile.jpg" alt="">

        <p class="text-white leading-tight text-md lg:pr-6 text-center lg:text-left lg:text-xl ">
          Hi there! I'm data analyst and full stack developer, rapidly turning idea into reality. I have a degree in
          mechanical engineering from University of Applied Science Darmstadt, Germany , but I found my true calling in
          data analytics and web development field.
          <br><br> Currently working as associate in Khazanah Nasional Berhad, where I use my technical skills to deliver
          solutions using Agile methodology (Scrum). Some of the cool things I've done are building a search engine,
          automating data processes and developing Power BI reports.
          I'm always eager to learn new things and face new challenges.
          <br><br>
          If you are curious about me or my work, don't hesitate to connect with me on <a href="https://www.linkedin.com/in/nazmisyed/" target="_blank" class="text-firstColor hover:cursor-pointer">LinkedIn.</a> 

        </p>
      </div>
    </section>
    <section class="mx-3 lg:mx-14 mb-14" id="skills">

    
    <div class="text-firstColor text-2xl mb-6   lg:text-6xl">Skills</div>
    <div class="flex flex-col   my-3 gap-y-7 lg:gap-x-7 lg:flex-row">
      <div class="bg-secondColor rounded-sm  p-5 opacity-90 hover:opacity-100 lg:w-1/5 lg:h-96">
        <span class="text-firstColor text-xl font-bold lg:text-2xl"> Data Analytics </span>
        <br><span class="text-thirdColor text-md ">Python</span>
        <br><span class="text-thirdColor text-md ">Pandas</span>
        <br><span class="text-thirdColor text-md ">Numpy</span>
        <br><span class="text-thirdColor text-md ">Data Visualization</span>
        <br><span class="text-thirdColor text-md ">Web Scraping</span>
        <br><span class="text-thirdColor text-md ">Power BI</span>
      </div>
      <div class="bg-secondColor rounded-sm   p-5 opacity-90 hover:opacity-100 lg:w-1/5 lg:h-96">
        <span class="text-firstColor text-2xl font-bold lg:text-2xl"> Data Science </span>
        <br><span class="text-thirdColor text-md ">Scikit-learn</span>
        <br><span class="text-thirdColor text-md ">Supervised Learning</span>
        <br><span class="text-thirdColor text-md ">Unsupervised Learning</span>
        <br><span class="text-thirdColor ttext-md ">Deep Learning</span>
        <br><span class="text-thirdColor text-md ">Natural Language Processing</span>
        <br><span class="text-thirdColor text-md ">Semantic Search</span>
        <br><span class="text-thirdColor text-md ">Sentiment Analysis</span>
        <br><span class="text-thirdColor text-md ">Text Classification</span>
        <br><span class="text-thirdColor text-md ">GPT-4</span>
      </div>
      <div class="bg-secondColor rounded-sm  p-5 opacity-90 hover:opacity-100 lg:w-1/5 lg:h-96">
        <span class="text-firstColor text-2xl font-bold lg:text-2xl"> Cloud Technology </span>
        <br><span class="text-thirdColor text-md ">Azure</span>
        <br><span class="text-thirdColor p-4 text-md ">App Service</span>
        <br><span class="text-thirdColor p-4 text-md ">Functions</span>
        <br><span class="text-thirdColor p-4 text-md ">Static Web App</span>
        <br><span class="text-thirdColor p-4 text-md ">SQL</span>
        <br><span class="text-thirdColor p-4 text-md ">Cosmos DB</span>
        <br><span class="text-thirdColor p-4 text-md ">Blob Storage</span>
        <br><span class="text-thirdColor p-4 text-md ">Machine Learning Studio </span>
        <br><span class="text-thirdColor p-4 text-md ">Form Recognizer </span>
        <br><span class="text-thirdColor p-4 text-md ">Translation</span>
        <br><span class="text-thirdColor p-4 text-md ">Active Directory</span>
      </div>
      <div class="bg-secondColor rounded-sm  p-5 opacity-90 hover:opacity-100 lg:w-1/5 lg:h-96">
        <span class="text-firstColor text-2xl font-bold lg:text-2xl">Front-End </span>
        <br><span class="text-thirdColor text-md ">HTML</span>
        <br><span class="text-thirdColor text-md ">Tailwind CSS</span>
        <br><span class="text-thirdColor text-md ">Javascript</span>
        <br><span class="text-thirdColor text-md ">Vue JS</span>
        <br><span class="text-thirdColor text-md ">Nuxt 3</span>
        <br><span class="text-thirdColor text-md ">VueX</span>
        <br><span class="text-thirdColor text-md ">ChartJS</span>
        <br><span class="text-thirdColor text-md ">Leaflet</span>
        <br><span class="text-thirdColor text-md ">Lodash</span>
        <br><span class="text-thirdColor text-md ">i18n</span>
      </div>
      <div class="bg-secondColor rounded-sm p-5 opacity-90 hover:opacity-100 lg:w-1/5 lg:h-96">
        <span class="text-firstColor text-2xl font-bold lg:text-2xl">Others </span>
        <br><span class="text-thirdColor text-md ">Scrum</span>
        <br><span class="text-thirdColor text-md ">Power Apps</span>
        <br><span class="text-thirdColor text-md ">Power Automate</span>
        <br><span class="text-thirdColor text-md ">Streamlit</span>
        <br><span class="text-thirdColor text-md ">Fast API</span>
        

      </div>
    </div>
  </section>
  <section class="mx-3 lg:mx-14 mb-14" id="work-experiences">
    <div class="text-firstColor text-2xl mb-6 lg:text-6xl">Work Experiences</div>

    <BaseAccordion :companyName="work1" :contents="workExp1" :id="workId1" />
    <BaseAccordion :companyName="work2" :contents="workExp2" :id="workId2"  />
  </section>
  </div>
  <div>
      <TheFooter></TheFooter>
    </div>
</template>

<script>
// @ is an alias to /src
import TheFooter from '@/components/TheFooter.vue';
import TheHero from '@/components/TheHero.vue'
import BaseAccordion from  '@/components/BaseAccordion.vue'


export default {
  name: 'HomeView',
  components: {
    TheHero,
    TheFooter,
    BaseAccordion
},
  data() {
    return {
      workId1:"knb",
      work1:"Khazanah Nasional Berhad",
      workExp1:[{positionNYear:"Associate, Digital And Technology ( Oct 2021 - Present )",description:"Skillful in building PowerBI reports, developing analytics and AI-powered applications, and designing architecture for multiple applications in Azure. Experienced in delivering value with Scrum framework, automating data processes and exploring alternative data usage for investment. Passionate about mentoring staff in PowerBI, Python, Javascript, and Azure to foster a culture of continuous learning and improvement. Adept at conducting rapid Proof Of Concept (POC) building and interviewing investment teams for data value creation."},
      {positionNYear:"Graduate Trainee, Digital And Technology ( Oct 2020 - Sep 2021 )",description:"Collected, analyzed, and stored data to create value for the organization. Went through fast-paced financial analysis training to enhance the ability to provide key insights to stakeholders."}],
      workId2:"intern",
      work2:"5 Internships In Germany",
      workExp2:[{positionNYear:"SK Laser GmbH ( Aug 2019 - Oct 2019 )",description:"Developed working prototype autofocus system for their commercial product within 10 weeks, completed tasks within short deadlines, including programming a new user interface and building the electronic circuit for the laser machine"},
      {positionNYear:"Schoder GmbH ( Mar 2019 )",description:"Acquired insights into CNC laser cutting, milling, and bending machines, gained exposure to an international working environment, and obtained knowledge of the metal processing workflow from customer order to packaging."},
      {positionNYear:"Härtetechnik Rossdorf GmbH ( Nov 2018 )",description:"Obtained insight into various heat treatment processes including annealing, hardening, tempering, nitriding, and normalizing."},
      {positionNYear:"Karl Schmeer Modellbau GmbH (Sep 2017)",description:"Learned skills in permanent mold casting, fettling, sandblasting, and crafting of sand molds."},
      {positionNYear:"Kölner Verkehrs-Betriebe AG ( May 2016 - Jul 2016 )",description:"Learned basic metal processing techniques including drilling and sawing, machining processes such as milling and turning, joining techniques such as welding and riveting, and quality control procedures"}
    
    ],

    }
  },
  computed: {

  },
  created() {
    

  }
}
</script>
