<template>
    <div id="accordionExample">
        <div class=" border border-black bg-secondColor">
            <h2 class="mb-0" id="headingOne">
                <button
                    class="group relative flex w-full text-2xl items-center rounded-t-[15px] border-0 bg-secondColor py-4 px-5 text-left  font-bold text-firstColor transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none  [&:not([data-te-collapse-collapsed])]:bg-secondColor [&:not([data-te-collapse-collapsed])]:text-firstColor [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)]"
                    type="button" data-te-collapse-init data-te-collapse-collapsed :data-te-target="`#${id}`" aria-expanded="false"
                    :aria-controls="id">
                    {{ companyName }}
                    <span
                        class="ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-firstColor transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="h-6 w-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>
                    </span>
                </button>
            </h2>
            <div :id="id" class="!visible hidden" data-te-collapse-item  aria-labelledby="headingOne"
                data-te-parent="#accordionExample">
                <div class="py-4 px-5 text-white" v-for="content in contents">
                    <strong>{{content.positionNYear  }}</strong>
                    <br>
                    {{ content.description }}
                </div>
            </div>
        </div>


    </div>
</template>

<script>
export default {
    props: {
        id:{
            type:String,
            default:"myId"},
        companyName: {
            type: String,
            default: "Company"
        },
        contents: {
            type: Array,
            default: [{ positionNYear: "Position 2021 - Current", description: "As a highly skilled Data Analyst, I possess the ability to analyze large datasets and extract meaningful patterns, trends, and insights. I have a proven track record of developing and implementing statistical models to analyze data and make predictions. With my expertise in creating compelling visualizations and reports, I am able to clearly communicate findings to stakeholders, including the management team" }]
        }
    },

}
</script>

