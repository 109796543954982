<template>
  <div id="main-header-container" class="flex flex-row mx-auto py-3 px-3 md:px-14 bg-bgColor justify-between">
    <div class="text-firstColor font-bold">
      nazmianalytics
    </div>
    <nav
      class="flex flex-row relative container justify-end gap-x-2 text-firstColor lg:gap-x-8"
    >
      
      <!-- <router-link to="/skill">Skills</router-link> -->
      <!-- <router-link to="/chatgpt">Demo</router-link> -->
    </nav>
  </div>
</template>

<script>
export default {
  name: "TheHeader",
  props: {},
};
</script>
