<template>
  <div class="h-full bg-bgColor">
  <TheHeader :class="['sticky top-0 z-50', isChatPage ? 'hidden' : 'block']" />
  
    <router-view></router-view>
  </div>
</template>
<script>
import TheHeader from "@/components/TheHeader.vue";

export default {
  name: "App",
  components: { TheHeader },
  data() {
    return {

    };
  },
  computed: {
    isChatPage() {
      // console.log(this.$route.name)
      return (this.$route.name == "chatgpt")
    }
  }
};
</script>

<style></style>
