<template>

  <div class="h-screen md:h-[50vh] mb-12 bg-fixed bg-center bg-cover custom-img">
      
       <div class="absolute top-1/3  mx-14 lg:top-1/4  ">
        <div class="font-bold  text-thirdColor text-2xl  lg:text-6xl">
         Discovering Possibilities With Data
        </div>
        <div class="font-bold  w-full text-thirdColor mt-3 text-md lg:text-xl">
          Data Analyst
          <br>
          Full Stack Developer
        </div>
      </div>


    </div>
</template>

<script>
export default {
  name: "Hero",
  props: {},
};
</script>

<style>
.custom-img {
  background-image: url("~@/assets/Hero.jpeg");
}

</style>
